<template>
  <div class="self-review bj">
    <div class="inputtop">
      <el-input
        style=""
        clearable
        v-model="code"
        placeholder="请在此输入或扫码复核码"
        autocomplete="off"
        autofocus="true"
        ref="inputfocusc"
        @keyup.enter.native="changeInput"
      />
      <div class="seach">
        <el-button type="primary" icon="el-icon-search" @click="changeInput"
          >查询</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <div v-if="isShowInfo" class="inputbody">
        <div class="inputitems">
          <div class="inputwen">城市仓:</div>
          <div class="widthinput">
            <el-select
              filterable
              v-model="info.logistics_id"
              clearable
              :disabled="info.qc_status != 10"
              @change="changelogistics"
              placeholder="选择城市仓"
            >
              <el-option
                v-for="item in cityStoreList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="inputwen">自提点:</div>
          <div class="widthinput">
            <el-select
              filterable
              v-model="info.delivery_id"
              :disabled="info.qc_status != 10"
              clearable
              placeholder="选择自提点"
            >
              <el-option
                v-for="item in zitList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="inputitems" style="align-items: flex-start">
          <div class="inputwen1 inputwen">自采品 登记：</div>
          <div class="cntsbody">
            <div
              class="cntlist"
              v-for="(item, index) in info.list"
              :key="index"
            >
              {{ item.product_name }}
              <span>{{
                info.qc_status == 10 ? item.product_num : item.qc_product_num
              }}</span
              >件 共<span>{{
                info.qc_status == 10 ? item.weight : item.qc_weight
              }}</span
              >斤
              <div
                style="display: flex; align-items: center"
                v-if="info.qc_status == 10"
              >
                复核：{{ item.product_name }}

                <div class="widthinput1">
                  <el-input
                    v-model="item.qc_product_num"
                    placeholder="复核数量"
                    clearable
                  ></el-input>
                </div>
                件,共
                <div class="widthinput1">
                  <el-input
                    v-model="item.qc_weight"
                    placeholder="复核重量"
                    clearable
                  ></el-input>
                </div>
                斤
              </div>
            </div>
          </div>
        </div>
        <div class="inputitems">
          <div class="inputwen">收货人:</div>
          <div class="widthinput">
            <el-input
              v-model="info.receiver_name"
              placeholder="收货人姓名"
              :disabled="info.qc_status != 10"
              clearable
            ></el-input>
          </div>
          <div class="widthinput">
            <el-input
              v-model="info.receiver_mobile"
              placeholder="收货人电话"
              :disabled="info.qc_status != 10"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="inputitems">
          <div class="inputwen">发货人:</div>
          <div class="widthinput">
            <el-input
              v-model="info.shipper_name"
              placeholder="发货人姓名"
              :disabled="info.qc_status != 10"
              clearable
            ></el-input>
          </div>
          <div class="widthinput">
            <el-input
              v-model="info.shipper_mobile"
              placeholder="发货人电话"
              :disabled="info.qc_status != 10"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="inputitems">
          <div class="inputwen">登记人:</div>
          {{ info.business_user_name }}
        </div>
        <div class="inputitems">
          <div class="inputwen">登记时间:</div>
          {{ info.create_time }}
        </div>
        <div class="inputitems">
          <el-button
          type="warning"
          v-if="info.qc_status >10"
          @click="PrintingSelf"
        
          >打印自采单</el-button
        >
        <el-button
          type="warning"
          v-if="info.qc_status >10"
          @click="Printingtag"
        
          >打印标签</el-button
        >
        </div>
      </div>

      <c-empty-page v-else tip="请扫描或者输入自采品二维码~"></c-empty-page>
    </div>
    <div class="btn">
      <el-button type="info" v-if="info.qc_status == 10" @click="confirm('1')"
        >未上车</el-button
      >
      <el-button
        type="warning"
        v-if="info.qc_status == 10"
        @click="confirm('2')"
        >确认提交</el-button
      >
    </div>
        <!-- 打印标签 -->
        <el-dialog
      :visible.sync="setWindowDialog"
      title="选择调度室窗口"
      width="40%"
      :center="true"
      class="dialog1"
    >
      <div class="contents">
        <el-form
          :model="dialogForm"
          label-position="right"
          ref="dialogForm"
          :rules="rule1"
        >
          <el-form-item label="调度室窗口" prop="window_id">
            <el-select
              v-model="dialogForm.window_id"
              placeholder="请选择调度室窗口"
              class="handle-select mr10"
            >
              <el-option
                :key="index"
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in windowList"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-row v-for="(item, i) in dialogForm.print_arr" :key="i">
            <div class="position">
              <el-form-item
                :label="`${item.product_name}——打印件数`"
                :prop="`print_arr.${i}.product_num`"
                :rules="rulesnum"
              >
                <el-input
                  v-model="item.product_num"
                  :disabled="false"
                  clearable
                  placeholder="请输入打印件数"
                ></el-input>
              </el-form-item>
            </div>
          </el-row>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="adminWindow">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
  <script>
import CEmptyPage from "@/components/common/CEmptyPage";
import debounce from "lodash/debounce";
import { BASE } from "@/api";
export default {
  name: "self-review",
  components: { CEmptyPage },
  data() {
    return {
      code: "",
      codecopy: "",
      isShowInfo: false,
      info: "",
      cityStoreList: [],
      zitList: [],
      setWindowDialog: false,
      windowList: [],
      dialogForm: { window_id: "", print_arr: [] },
      rule1: {
        window_id: [
          { required: true, message: "请选择调度室窗口", trigger: "blur" },
        ],
      },
      rulesnum: [
        {
          required: true,
          message: "请输入件数",
          trigger: "blur",
        },
        {
          validator: (rule, value, callback) => {
            if (/^(?:[1-9]\d*)$/.test(value) == false) {
              callback(new Error("请输入正整数"));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.$refs.inputfocusc.focus();
    this.onInitData();
  },
  methods: {
    roomWindows() {
      this.$api.logistics.roomWindows().then((res) => {
        if (res.code == 20000) {
          this.windowList = res.data;
        }
      });
    },
    Printingtag() {
      this.setWindowDialog = true;
    },
    adminWindow() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.$api.logistics
            .productlabelprint({
              self_supply_no: this.code,
              ...this.dialogForm,
            })
            .then((res) => {
              //   console.log(res, "打印成功还没成功");
              this.$message({
                message: "打印成功",
                type: "success",
              });
              this.setWindowDialog = false;
            });
        }
      });
    },
    PrintingSelf() {
      // /api

      window.open(
        `${BASE.PRO2 + "/selfPurchase/pdf/print"}` +
          `?self_supply_no=${this.code}&token=${
            sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : ""
          }`
      );
    },
    onInitData() {
      this.roomWindows()
      this.getAjaxCityStoreList();
    },
    confirm(e) {
      this.$api.logistics
        .selfPurchaseEntruckSave({
          self_supply_no: this.codecopy,
          op_type: e,
          ...this.info,
        })
        .then((res) => {
          console.log(res, "确定一下");
          this.info.qc_status = 20;
          this.$message({
            type: "success",
            message: "复核成功",
          });
        });
    },
    //弹框选择城市仓联动自提点
    changelogistics(e) {
      this.zitList = []; // 重置自提点
      this.info.delivery_id = ""; // 重置自提点搜索
      const params = {
        logistics_id: e,
        page: 1,
        pageSize: 999999,
      };
      this.$api.logistics.entruckDeliverylist(params).then((res) => {
        this.zitList = res.data.data;
      });
    },
    changeInput() {
      if (this.code) {
        this.$api.logistics
          .selfPurchaseEntruckDetail({ self_supply_no: this.code })
          .then((res) => {
            console.log(res, "获取数据");
            this.info = res.data;
            if (this.info.qc_status == 10) {
              this.info.list.forEach((el) => {
                el.qc_product_num = el.product_num;
                el.qc_weight = el.weight;
              });
            }
            this.codecopy = this.code;
            this.dialogForm.print_arr = this.info.list;
            this.$api.logistics
              .entruckDeliverylist({
                logistics_id: this.info.logistics_id,
                page: 1,
                pageSize: 999999,
              })
              .then((res) => {
                this.zitList = res.data.data;
              });
            this.isShowInfo = true;
          })
          .catch((err) => {
            this.isShowInfo = false;
          });
      } else {
        this.isShowInfo = false;
      }
    },

    /**
     * 获取城市仓列表
     */
    getAjaxCityStoreList: debounce(async function (data) {
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.logistics.entruckLogisticslist(params);
        this.cityStoreList = data.data;
        console.log("ajx getAjaxCityStoreList", data.data);
      } catch (err) {
        console.log("ajx getAjaxCityStoreList err", err);
      }
    }, 500),
  },
};
</script>
  <style lang="scss">
  .dialog1{

  }
.self-review {
  height: 100%;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-sizing: border-box;
  /** 修改element基础样式 */
  .el-select .el-input.is-disabled .el-input__inner {
    color: #333333;
  }

  .el-input.is-disabled .el-input__inner {
    color: #333333;
  }
  .inputbody {
    padding: 40px 100px;
    .inputitems {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-size: 22px;
      .inputwen {
        // width: 200px;
        font-size: 30px;

        margin-right: 20px;
        display: flex;
        align-items: center;
      }
      .inputwen1 {
        align-items: flex-start;
        // padding-top: 5px;
      }
      .widthinput {
        width: 300px;
        margin: 0 20px;
      }
      .widthinput1 {
        width: 200px;
        margin: 0 20px;
      }
      .cntsbody {
        .cntlist {
          font-size: 22px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          div {
            font-size: 22px;
            margin-left: 20px;
          }
          span {
            color: #13ae67;
            font-size: 22px;
            margin: 0 20px;
          }
        }
        display: flex;
        flex-direction: column;
        // align-items: flex-start;
        // justify-content: flex-end;
      }
    }
  }

  .inputtop {
    display: flex;
    margin-bottom: 6px;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-left: 8px;
    }
  }
  .auto-table-flex {
    flex-grow: 1;
    overflow-y: auto;
    .ctns {
      padding: 23px 12px;
      box-sizing: border-box;
    }
  }
  .btn {
    border-top: 1px solid #e8e8e8;
    display: flex;
    padding: 14px 0;

    box-sizing: border-box;
    justify-content: center;
  }
  .shij {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 56px;
    border: 1px solid #666666;
    border-bottom: 0;
  }
  .mt {
    border-top: 0;
  }

  .divtitle {
    display: flex;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    .itms {
      flex: 1;
      height: 89px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #666666;
      border-right: 0;
    }
  }
  .si {
    display: flex;
    .item {
      flex: 1;
      padding: 18px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #666666;
      border-right: 0;
    }
  }
}
</style>
  