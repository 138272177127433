var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "self-review bj" },
    [
      _c(
        "div",
        { staticClass: "inputtop" },
        [
          _c("el-input", {
            ref: "inputfocusc",
            attrs: {
              clearable: "",
              placeholder: "请在此输入或扫码复核码",
              autocomplete: "off",
              autofocus: "true",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.changeInput.apply(null, arguments)
              },
            },
            model: {
              value: _vm.code,
              callback: function ($$v) {
                _vm.code = $$v
              },
              expression: "code",
            },
          }),
          _c(
            "div",
            { staticClass: "seach" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.changeInput },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _vm.isShowInfo
            ? _c("div", { staticClass: "inputbody" }, [
                _c("div", { staticClass: "inputitems" }, [
                  _c("div", { staticClass: "inputwen" }, [_vm._v("城市仓:")]),
                  _c(
                    "div",
                    { staticClass: "widthinput" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            disabled: _vm.info.qc_status != 10,
                            placeholder: "选择城市仓",
                          },
                          on: { change: _vm.changelogistics },
                          model: {
                            value: _vm.info.logistics_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "logistics_id", $$v)
                            },
                            expression: "info.logistics_id",
                          },
                        },
                        _vm._l(_vm.cityStoreList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "inputwen" }, [_vm._v("自提点:")]),
                  _c(
                    "div",
                    { staticClass: "widthinput" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            disabled: _vm.info.qc_status != 10,
                            clearable: "",
                            placeholder: "选择自提点",
                          },
                          model: {
                            value: _vm.info.delivery_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "delivery_id", $$v)
                            },
                            expression: "info.delivery_id",
                          },
                        },
                        _vm._l(_vm.zitList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "inputitems",
                    staticStyle: { "align-items": "flex-start" },
                  },
                  [
                    _c("div", { staticClass: "inputwen1 inputwen" }, [
                      _vm._v("自采品 登记："),
                    ]),
                    _c(
                      "div",
                      { staticClass: "cntsbody" },
                      _vm._l(_vm.info.list, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "cntlist" },
                          [
                            _vm._v(" " + _vm._s(item.product_name) + " "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.info.qc_status == 10
                                    ? item.product_num
                                    : item.qc_product_num
                                )
                              ),
                            ]),
                            _vm._v("件 共"),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.info.qc_status == 10
                                    ? item.weight
                                    : item.qc_weight
                                )
                              ),
                            ]),
                            _vm._v("斤 "),
                            _vm.info.qc_status == 10
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " 复核：" +
                                        _vm._s(item.product_name) +
                                        " "
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "widthinput1" },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "复核数量",
                                            clearable: "",
                                          },
                                          model: {
                                            value: item.qc_product_num,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "qc_product_num",
                                                $$v
                                              )
                                            },
                                            expression: "item.qc_product_num",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 件,共 "),
                                    _c(
                                      "div",
                                      { staticClass: "widthinput1" },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "复核重量",
                                            clearable: "",
                                          },
                                          model: {
                                            value: item.qc_weight,
                                            callback: function ($$v) {
                                              _vm.$set(item, "qc_weight", $$v)
                                            },
                                            expression: "item.qc_weight",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" 斤 "),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _c("div", { staticClass: "inputitems" }, [
                  _c("div", { staticClass: "inputwen" }, [_vm._v("收货人:")]),
                  _c(
                    "div",
                    { staticClass: "widthinput" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "收货人姓名",
                          disabled: _vm.info.qc_status != 10,
                          clearable: "",
                        },
                        model: {
                          value: _vm.info.receiver_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "receiver_name", $$v)
                          },
                          expression: "info.receiver_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "widthinput" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "收货人电话",
                          disabled: _vm.info.qc_status != 10,
                          clearable: "",
                        },
                        model: {
                          value: _vm.info.receiver_mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "receiver_mobile", $$v)
                          },
                          expression: "info.receiver_mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "inputitems" }, [
                  _c("div", { staticClass: "inputwen" }, [_vm._v("发货人:")]),
                  _c(
                    "div",
                    { staticClass: "widthinput" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "发货人姓名",
                          disabled: _vm.info.qc_status != 10,
                          clearable: "",
                        },
                        model: {
                          value: _vm.info.shipper_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "shipper_name", $$v)
                          },
                          expression: "info.shipper_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "widthinput" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "发货人电话",
                          disabled: _vm.info.qc_status != 10,
                          clearable: "",
                        },
                        model: {
                          value: _vm.info.shipper_mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "shipper_mobile", $$v)
                          },
                          expression: "info.shipper_mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "inputitems" }, [
                  _c("div", { staticClass: "inputwen" }, [_vm._v("登记人:")]),
                  _vm._v(" " + _vm._s(_vm.info.business_user_name) + " "),
                ]),
                _c("div", { staticClass: "inputitems" }, [
                  _c("div", { staticClass: "inputwen" }, [_vm._v("登记时间:")]),
                  _vm._v(" " + _vm._s(_vm.info.create_time) + " "),
                ]),
                _c(
                  "div",
                  { staticClass: "inputitems" },
                  [
                    _vm.info.qc_status > 10
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning" },
                            on: { click: _vm.PrintingSelf },
                          },
                          [_vm._v("打印自采单")]
                        )
                      : _vm._e(),
                    _vm.info.qc_status > 10
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning" },
                            on: { click: _vm.Printingtag },
                          },
                          [_vm._v("打印标签")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _c("c-empty-page", {
                attrs: { tip: "请扫描或者输入自采品二维码~" },
              }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _vm.info.qc_status == 10
            ? _c(
                "el-button",
                {
                  attrs: { type: "info" },
                  on: {
                    click: function ($event) {
                      return _vm.confirm("1")
                    },
                  },
                },
                [_vm._v("未上车")]
              )
            : _vm._e(),
          _vm.info.qc_status == 10
            ? _c(
                "el-button",
                {
                  attrs: { type: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.confirm("2")
                    },
                  },
                },
                [_vm._v("确认提交")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog1",
          attrs: {
            visible: _vm.setWindowDialog,
            title: "选择调度室窗口",
            width: "40%",
            center: true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.setWindowDialog = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c("el-button", { on: { click: _vm.adminWindow } }, [
                        _vm._v("确定"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "contents" },
            [
              _c(
                "el-form",
                {
                  ref: "dialogForm",
                  attrs: {
                    model: _vm.dialogForm,
                    "label-position": "right",
                    rules: _vm.rule1,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "调度室窗口", prop: "window_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "handle-select mr10",
                          attrs: { placeholder: "请选择调度室窗口" },
                          model: {
                            value: _vm.dialogForm.window_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.dialogForm, "window_id", $$v)
                            },
                            expression: "dialogForm.window_id",
                          },
                        },
                        _vm._l(_vm.windowList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.dialogForm.print_arr, function (item, i) {
                    return _c("el-row", { key: i }, [
                      _c(
                        "div",
                        { staticClass: "position" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: `${item.product_name}——打印件数`,
                                prop: `print_arr.${i}.product_num`,
                                rules: _vm.rulesnum,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: false,
                                  clearable: "",
                                  placeholder: "请输入打印件数",
                                },
                                model: {
                                  value: item.product_num,
                                  callback: function ($$v) {
                                    _vm.$set(item, "product_num", $$v)
                                  },
                                  expression: "item.product_num",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }